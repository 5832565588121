<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col sm="12" md="6" lg="5">
              <b-card no-body>
                <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                  <img src="@/icon/2333399.png" style="width: 2em;"> Üyelik Paketleri
                  <b-button v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" variant="secondary" size="" class="pull-right" @click="f_addNewChildWisdomData('wdm244')" title="yeni paket ekle">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-card-header>
                <template v-for="(wdmr244, wdmr244_ind) in d_wdmr244List">
                  <b-row style="margin: 0px;" :style="('wdm244_' + d_selectedwdmr244Data.id) === wdmr244.key ? 'color: green; margin: 0px; cursor: pointer;' : 'margin: 0px; cursor: pointer;'">
                    <b-col sm="12" lg="10" @click="f_getWdmrData(wdmr244.key)">
                      <img src="@/icon/934385.png" style="width: 2em;"> {{ wdmr244.label }}
                    </b-col>
                    <b-col sm="12" lg="2">
                      <!-- <div @click="f_goToDocumentDetails(wdmr244.key)"><a href="javascript:">detaylar...</a></div> -->
                      <b-dropdown v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1 && ('wdm244_' + d_selectedwdmr244Data.id) === wdmr244.key" class="pull-right" variant="secondary" text="İşlemler" size="sm">
                        <b-dropdown-item @click="f_editChildWdmr('wdm244')">düzenle</b-dropdown-item>
                        <!-- <b-dropdown-item @click="f_goToDocumentDetails(wdmr244.key)">döküman detaylarına git</b-dropdown-item> -->
                        <b-dropdown-item v-for="(rel, rel_ind) in d_parentChildData.child.other_possible_relations" @click="f_otherRelation(wdmr244, rel)">{{ rel.label }}</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </b-col>
            <b-col sm="12" md="6" lg="7">
              <template v-if="d_selectedwdmr244Data && d_selectedwdmr244Data.id">
                <b-card no-body>
                  <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                    {{ d_selectedwdmr244Data.label }}
                  </b-card-header>
                  <div>
                    <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedwdmr244Data" :data_type="'wdm244'" :option_data="d_wisdomData.option_data['wdm244']"></wisdom-data-show>
                  </div>
                </b-card>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="background: black;" no-body>
      <web-bottom></web-bottom>
    </b-card>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'1000'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveChildWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_otherRelationData.show" @close="d_otherRelationData.show = false" :width="'500'">
      <h3 slot="header">{{ d_otherRelationData.item.label }} <small style="color: red;">({{ d_otherRelationData.item.key }})</small></h3>
      <div slot="body">
        <b-form-select v-model="d_otherRelationData.selected">
          <option v-for="(x, x_ind) in d_otherRelationData.list" :value="x">
            {{ x.label }}
          </option>
        </b-form-select>
      </div>
      <div slot="footer">
        <button v-if="d_otherRelationData.selected" type="button" class="btn btn-success" v-on:click="f_applyItemRelation()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_otherRelationData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script type="text/javascript">
import WebBottom from '@/components/widgets/WebBottom';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import GlobalService from '@/services/global';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
export default {
  name: 'PermissionPackageList',
  computed: {},
  props: {},
  components: {
    WisdomDataModal,
    WisdomDataShow,
    Modal,
    WebBottom
  },
  data () {
    return {
      d_parentChildData: {
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': '',
          'type': 'wdm243',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm244_v2.0',
          'type': 'wdm244',
          'bucket': 'wisdom',
          'other_possible_relations': [{
            'label': 'Web sayfaları ile ilişkilendir',
            'relation_type': 'keys',
            'parent': { 'key': 'wdm232_1', 'type': 'wdm232', 'bucket': 'wisdom', },
            'child': { 'reference_wdm_key': 'wdm_wisdom_wdm232_v2.0', 'type': 'wdm232', 'bucket': 'wisdom' }
          }]
        }
      },
      d_otherRelationData: {
        'show': false,
        'item': { 'label': '', 'key': '' },
        'selected': '',
        'relation': '',
        'list': []
      },
      user: '',
      d_wdmr244List: [],
      d_selectedwdmr244Data: {},
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmr243: {
        'id': 1
      }
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    if (this.d_wdmr243) {
      this.f_getWdmr244List();
      this.f_wdmList();
    }
  },
  methods: {
    f_applyItemRelation: function () {
      // Now we anly need to add child key to parent keys document. But we send also child wdmr.
      // Because we will maybe add some child wdmr's parameters to the parent keys document in the future.
      // console.log('this.d_wisdomData', this.d_wisdomData);
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      parent_wdm = {
        'label': '',
        'key': this.d_otherRelationData.selected.key,
        'bucket': this.d_otherRelationData.relation.parent.bucket,
        'type': this.d_otherRelationData.relation.parent.type
      };
      child_wdm = {
        'label': '',
        'key': this.d_parentChildData.child.reference_wdm_key,
        'bucket': this.d_parentChildData.child.bucket,
        'type': this.d_parentChildData.child.type
      };
      relation_type = this.d_otherRelationData.relation.relation_type;
      let d_wisdomData = {
        'wisdom_op': 'edit',
        'data_type': this.d_parentChildData.child.type,
        'wisdom_data': this.d_selectedwdmr244Data,
        'option_data': {}
      };
      d_wisdomData.option_data[this.d_parentChildData.child.type] = this.d_wisdomData.option_data[this.d_parentChildData.child.type];
      ClsWdmrManage.save_child_wdmr(d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          this.d_otherRelationData.show = false;
        }, resp => {
          console.log('error :', resp);
          alert('error ', resp);
        });
    },
    f_otherRelation: function (item, relation) {
      /*
        Any relation looks like:
        relation = {
            'label': 'Web sayfaları ile ilişkilendir',
            'relation_type': 'keys',
            'parent': { 'key': '', 'type': 'wdm233', 'bucket': 'wisdom', },
            'child': { 'reference_wdm_key': 'wdm_wisdom_wdm232_v2.0', 'type': 'wdm232', 'bucket': 'wisdom' }
        }
      */
      this.f_getChildWdmrList(relation.parent, relation.child, relation.relation_type);
      this.d_otherRelationData.item = item;
      this.d_otherRelationData.relation = relation;
      this.d_otherRelationData.show = true;
    },
    f_getChildWdmrList: function (parent, child, relation_type) {
      this.d_otherRelationData.list = [];
      ClsWdmrManage.get_related_wdmr_list(parent, child, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_otherRelationData.list = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
    f_goToDocumentDetails: function (wdmr244_key) {
      let route_data = {
        'name': 'documenttree',
        'path': 'documenttree',
        'query': {
          'wdmr244': wdmr244_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    },
    f_getWdmrData: function (wdmr_key) {
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedwdmr244Data = resp.data.result;
          } else {
            this.d_selectedwdmr244Data = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
          this.f_changeFunc();
        });
    },
    f_editChildWdmr: function (data_type) {
      this.d_wisdomData.wisdom_op = "edit";
      this.d_wisdomData.data_type = data_type;
      this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedwdmr244Data));
      this.d_wisdomData.show = true;
    },
    f_saveChildWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      if (this.d_wisdomData.data_type === 'wdm244') {
        parent_wdm = {
          'label': '',
          'key': 'wdm243_' + this.d_wdmr243.id,
          'bucket': 'wisdom',
          'type': 'wdm243'
        };
        child_wdm = {
          'label': '',
          'key': 'wdm_wisdom_wdm244_v2.0',
          'bucket': 'wisdom',
          'type': 'wdm244'
        };
        relation_type = 'keys';
      }
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          let new_wdmr244 = {
            'label': resp.wdmr.label ? resp.wdmr.label : '',
            'key': 'wdm244_' + resp.wdmr.id
          };
          if (this.d_wisdomData.wisdom_op === 'new') {
            this.d_wdmr244List.push(new_wdmr244);
          } else if (this.d_wisdomData.wisdom_op === 'edit') {
            for (let i in this.d_wdmr244List) {
              if (this.d_wdmr244List[i].key = 'wdm244_' + resp.wdmr.id) {
                this.d_wdmr244List[i].label = resp.wdmr.label ? resp.wdmr.label : '';
                break;
              }
            }
            this.d_selectedwdmr244Data = resp.wdmr;
          }
          this.d_wisdomData.show = false;
          this.f_changeFunc();
        }, resp => {
          alert('error ', resp);
        });
    },
    f_changeFunc: function () {
      if (this.d_wisdomData.change === '0') {
        this.d_wisdomData.change = '1';
      } else {
        this.d_wisdomData.change = '0';
      }
      this.$forceUpdate();
    },
    f_addNewChildWisdomData: function (data_type) {
      ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
      this.d_wisdomData.wisdom_op = 'new';
      this.d_wisdomData.show = true;
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm244_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_getWdmr244List: function () {
      this.d_wdmr244List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm243_' + this.d_wdmr243.id,
        'bucket': 'wisdom',
        'type': 'wdm243'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'wisdom',
        'type': 'wdm244'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr244List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    }
  }
}

</script>

